<template>
  <v-main>
    <v-container>
      <v-container style="height: 2vh"></v-container>
      <v-row>
        <v-col md="3">
          <time-picker label="開始日" @on-ok-click="startDate = $event" @on-clear-click="startDate = null" />
        </v-col>
        <v-col md="3">
          <time-picker label="終了日" @on-ok-click="endDate = $event" @on-clear-click="endDate = null" />
        </v-col>
        <v-col>
          <v-btn @click="onSearchClick" color="primary" depressed>
            検索
          </v-btn>
        </v-col>
      </v-row>
      <div>
        <GmapMap :center="{lat:35.79586483994339, lng:139.78238582034984}" :zoom="3" map-type-id="terrain" :style=gmapStyle >
        <GmapMarker 
          :key="index" 
          v-for="(m, index) in markers" 
          :position="m.position"
          :clickable="true"
          :icon="m.icon"
          @click="onMarkerClick(m)"
          />
        </GmapMap>
        <DataDetail :record="selectedRecord" :showDataDetail="showDataDetail" @close-data-detail="closeDataDetail" />
      </div>
      <log-out-confirm :title="logoutTitle" :showLogOutConfirm="showLogOutConfirm" @close-logout-confirm="closeLogOutConfirm" />

      <!-- alert on snackbar -->
      <v-snackbar
        v-model="showError"
        top
      >
        {{ errorText }}
      </v-snackbar>

    </v-container>
  </v-main>
</template>

<script>
// import { GChart } from 'vue-google-charts'
import store from "@/store"
import axios from "axios";
import TimePicker from '@/components/TimePicker.vue'
import DataDetail from '@/components/DataDetail.vue'
import LogOutConfirm from "@/components/LogOutConfirm"

export default {
  components: {
    TimePicker,
    DataDetail,
    LogOutConfirm
    // GChart
  },
  name: "Map",
  data() {
    return {
      startDate: "",
      endDate: "",
      records: [],
      showDataDetail: false,
      selectedRecord: null,
      showLogOutConfirm: false,
      logoutTitle: "再認証が必要です。"
    }
  },
  computed: {
    gmapStyle() {
      return "height: 70vh; margin-bottom: 10vh;"
    },
    markers() {
      const markers = []
      for (var rec of this.records) {
        var icon = (rec.type == "biomass") ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png" : "http://maps.google.com/mapfiles/ms/icons/orange-dot.png" 
        var marker = {
          position: { lat: rec.latitude, lng: rec.longitude },
          icon: icon,
          record: rec
        }
        markers.push(marker)
      }
      return markers
    },
  },
  watch: {
  },
  methods: {
    fetchRecords(startDate, endDate) {
      var lineUserId = ''
      var token = ''
      var url = ''
      if (process.env.VUE_APP_DEPLOY_MODE == "PROD") {
        lineUserId = store.state.auth.lineUser.sub
        token = store.state.auth.lineAccessToken
        url = process.env.VUE_APP_API_ORIGIN + "/api/line_records/get?account_id=" + lineUserId
      } else {
        url = process.env.VUE_APP_API_ORIGIN + "/api/line_records_mock/get"
      }
      if (startDate) {
        startDate = startDate.replace("-", "").replace("-", "")
        url = url + "&start_date=" + startDate
      }
      if (endDate) {
        endDate = endDate.replace("-", "").replace("-", "")
        url = url + "&end_date=" + endDate
      }
      axios({
        method: "GET",
        url: url,
        withCredentials: true,
        headers: {
          "x-line-access-token": token
        },
        timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
      })
      .then(res => {
        this.records = res.data
      })
      .catch(error => {
        this.loading = false
        if (error.response) {
          if (error.response.status == 403) {
            this.$store.dispatch('logout')
          } else {
            this.errorText = error.response.data.detail
            this.showError = true
          }
        } else {
          this.errorText = "ネットワーク接続を確認してください"
          this.showError = true
        }
      });
    },
    onMarkerClick(m) {
      console.log(m)
      this.showDataDetail = true
      this.selectedRecord = m.record
    },
    closeDataDetail() {
      this.showDataDetail = false
    },
    onSearchClick() {
      this.fetchRecords(this.startDate, this.endDate)
    },
    closeLogOutConfirm() {
      this.showLogOutConfirm = false
    }
  },
  created() {
    this.fetchRecords()
  },
  mounted() {
  }
}
</script>
